import urljoin from "url-join";

import { paths } from "@paths";

import { ContactPointEnum } from "./structuredData.constants";

export const structuredWebsiteData = (shop) =>
  JSON.stringify({
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "The Volte - Designer Dress Hire Australia - Rent Online",
    description: shop ? shop.description : "",
    potentialAction: {
      "@type": "SearchAction",
      "query-input": "required name=q",
      target: urljoin("https://stunnerboutiqueau.com", paths.search, "?q={q}"),
    },
    url: "https://stunnerboutiqueau.com",
    sameAs: [
      "https://www.facebook.com/thevolte",
      "https://twitter.com/the_volte",
      "http://pinterest.com/thevolte/",
      "http://www.linkedin.com/company/the-volte/",
      "https://en.wikipedia.org/wiki/The_Volte_(company)",
      "https://www.youtube.com/@TheVolte",
    ],
  });

export const structuredOrganizationData = () =>
  JSON.stringify({
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "The Volte",
    legalName: "The Volte Group Pty Ltd",
    url: "https://stunnerboutiqueau.com",
    logo: {
      "@type": "ImageObject",
      url: "https://stunnerboutiqueau.com/images/the_volte_logo_black.svg",
    },
    foundingDate: "2016",
    founders: [
      {
        "@type": "Person",
        name: "Bernadette Olivier",
      },
      {
        "@type": "Person",
        name: "Jade Hirniak",
      },
    ],
    address: {
      "@type": "PostalAddress",
      streetAddress: "1/4 Gugeri Street",
      addressLocality: "Claremont",
      addressRegion: "WA",
      postalCode: "6010",
      addressCountry: "AU",
    },
    contactPoint: {
      "@type": ContactPointEnum.TYPE,
      contactType: ContactPointEnum.CONTACT_TYPE,
      telephone: ContactPointEnum.TELEPHONE,
      email: ContactPointEnum.EMAIL,
    },
    sameAs: [
      "https://www.facebook.com/thevolte",
      "https://twitter.com/the_volte",
      "http://pinterest.com/thevolte/",
      "http://www.linkedin.com/company/the-volte/",
      "https://en.wikipedia.org/wiki/The_Volte_(company)",
      "https://www.youtube.com/@TheVolte",
    ],
  });
